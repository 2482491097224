/**
 * @deprecated
 * 외부 링크 -> OutLinks
 * 내부 링크 -> types/common.ts
 */

export const Urls = {
  Introduce: '/',
  Qna: '/content/qna',
  QnaCategory: '/content/qna/category',
  QnaWrite: '/content/qna/write',
  QnaWriteComplete: '/content/qna/write/complete',
  Magazine: '/content/magazine',
  MagazineCategory: '/content/magazine/category',
  MagazineTag: '/content/magazine/tag',
  MyPage: '/content/mypage',
  Hospital: '/hospitals',
  Error: '/content/error',
} as const;

export const OutLinks = {
  Contact: 'https://company.doctornow.co.kr/contact/',
  Service: 'https://company.doctornow.co.kr/service',
  Notice: 'https://company.doctornow.co.kr/notice',
  Career: 'https://company.doctornow.co.kr/career',
  Company: 'https://company.doctornow.co.kr/company',
  Culture: 'https://company.doctornow.co.kr/culture',
  Partnership: 'https://company.doctornow.co.kr/partnership',
  NowPharmacyInfo:
    'https://company.doctornow.co.kr/%eb%82%98%ec%9a%b0%ec%95%bd%ea%b5%ad-%ed%99%8d',
};
