import dayjs from 'dayjs';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

import { OutLinks } from '@/constants/url';
import useIsResponsive from '@/hooks/useIsResponsive';
import { Tablet } from '@/styles/layout';
import { ROUTES } from '@/types/common';
import { addQueryString } from '@/utils/url';

import Instagram from '../../../../public/images/icon/Instagram.svg';
import Kakao from '../../../../public/images/icon/Kakao.svg';
import Naver from '../../../../public/images/icon/Naver.svg';
import YouTube from '../../../../public/images/icon/YouTube.svg';

const Footer = () => {
  const currentYear = dayjs().year();
  const { isPc } = useIsResponsive();
  return (
    <Wrapper>
      <Content>
        <IntroSection>
          <Service>
            <ul>
              <li>닥터나우</li>
              <li>
                <Link href={OutLinks.Company}>회사 소개</Link>
              </li>
              <li>
                <Link href={OutLinks.Culture}>팀 문화</Link>
              </li>
              <li>
                <Link href={OutLinks.Service}>서비스 소개</Link>
              </li>
              <li>
                <Link href={OutLinks.Partnership}>제휴 안내</Link>
              </li>
              <li>
                <Link href={OutLinks.Notice}>소식 · 공지</Link>
              </li>
              <li>
                <Link href={OutLinks.Career}>채용 공고</Link>
              </li>
            </ul>
            <ServiceLayoutWrapper>
              <ul>
                <li>서비스</li>
                <li>
                  <Link href={ROUTES.NON_BENEFIT.HOME.path}>
                    우리동네 진료 가격
                  </Link>
                </li>
                <li>
                  <Link
                    href={
                      ROUTES.HOSPITAL.NEIGHBORHOOD.path +
                      addQueryString({ keyword: '소아과' })
                    }
                  >
                    지역별 병원 정보
                  </Link>
                </li>
                <li>
                  <Link href={ROUTES.HOSPITAL.HOME.path}>방문 예약</Link>
                </li>
                <li>
                  <Link href={ROUTES.QNA.REALTIME.path}>실시간 의료상담</Link>
                </li>
                <li>
                  <Link href={ROUTES.MAGAZINE.HOME.path}>건강 매거진</Link>
                </li>
                <li>
                  <Link href={ROUTES.ENCYCLOPEDIA.LIST.path}>질병백과</Link>
                </li>
              </ul>
              <ul>
                <li>고객센터</li>
                <li>
                  <span>채팅 문의 : </span>
                  <Link href={'https://doctornow.channel.io/home'}>채널톡</Link>
                </li>
                <li>
                  <Link href="mailto:contact@doctornow.co.kr">
                    메일로 문의하기
                  </Link>
                </li>
              </ul>
            </ServiceLayoutWrapper>
          </Service>
        </IntroSection>
        <InfoSection>
          <section>
            <div>
              <Link
                href={
                  'https://company.doctornow.co.kr/board/?action=readpost&post_id=1029&bbspaged=2'
                }
              >
                개인정보 처리 방침
              </Link>
              {/*<span>개인민감정보(건강정보) 처리방침</span>*/}
              <Link
                href={
                  'https://company.doctornow.co.kr/board/?action=readpost&post_id=1028&bbspaged=2'
                }
              >
                서비스 이용약관
              </Link>
              {/*<span>개인정보 수집 이용</span>*/}
              {/*<span>고유식별정보 수집동의</span>*/}
            </div>
            <address>
              <p>(주) 닥터나우</p>
              <p>
                {`대표 정진웅,장지호 | 사업자 등록 번호 : 279-88-01452 ${
                  isPc ? `|` : '\n'
                } 통신판매업 신고번호 : 2024-서울강남-00439`}
              </p>
              <p>
                주소 : 서울특별시 강남구 역삼로17길 51, 6층
                {isPc ? ` | ` : '\n'}
                {''}
                <Link
                  href={
                    'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2798801452'
                  }
                >
                  사업자 정보 확인
                </Link>
              </p>
            </address>
            <Copyright>
              {`Copyright ${currentYear}. 닥터나우 Inc. All rights reserved.`}
            </Copyright>
          </section>
          <section>
            <Link href={'https://www.instagram.com/doctornow_official/'}>
              <a>
                <Instagram />
              </a>
            </Link>
            <Link href={'https://pf.kakao.com/_whSxnK'}>
              <a>
                <Kakao />
              </a>
            </Link>
            <Link href={'https://m.post.naver.com/doctornow'}>
              <a>
                <Naver />
              </a>
            </Link>
            <Link
              href={'https://www.youtube.com/channel/UCdDJbE9dUH6YSYttHyZKWGQ'}
            >
              <a>
                <YouTube />
              </a>
            </Link>
          </section>
        </InfoSection>
      </Content>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.color.GRAY20};
`;

const Content = styled.div`
  width: 100%;
  max-width: 768px;
  padding: 48px 24px;
`;

const IntroSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
  }

  svg {
    border-radius: 6px;
  }

  @media screen and (max-width: ${Tablet}) {
    flex-direction: column;
  }
`;

const Service = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  a {
    text-decoration: none;
    outline: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    flex: 1;
    list-style: none;
    width: 160px;

    li {
      ${({ theme }) => theme.typography.UI14R};
      margin-bottom: 8px;
      color: ${({ theme }) => theme.color.GRAY400};

      &:first-child {
        ${({ theme }) => theme.typography.UI14SB};
        color: ${({ theme }) => theme.color.GRAY600};
      }
    }
  }

  @media screen and (max-width: ${Tablet}) {
    flex-direction: column;
  }
`;

const ServiceLayoutWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ul:last-child {
    margin-top: 32px;
  }

  @media screen and (max-width: ${Tablet}) {
    ul:first-child {
      margin-top: 32px;
    }
  }
`;

const InfoSection = styled.section`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.GRAY100};
  padding: 20px 0 0 0;
  margin-top: 48px;
  display: flex;
  justify-content: space-between;

  section:last-of-type {
    display: flex;
    align-items: flex-end;
    margin-bottom: 6px;

    svg {
      margin-right: 16px;
      cursor: pointer;
    }
  }

  div:first-child {
    display: flex;

    @media screen and (max-width: ${Tablet}) {
      flex-direction: column;

      & > a:first-child {
        margin-bottom: 16px;
      }
    }

    a {
      margin-right: 24px;
      ${({ theme }) => theme.typography.UI14SB};
      color: ${({ theme }) => theme.color.GRAY600};

      &:nth-child(n + 2) {
        ${({ theme }) => theme.typography.UI14R};
        color: ${({ theme }) => theme.color.GRAY400};
      }

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  address {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    font-style: unset;
    margin-bottom: 16px;
    white-space: pre-wrap;
    ${({ theme }) => theme.typography.UI12R};

    & > p:first-of-type {
      ${({ theme }) => theme.typography.UI12SB};
      color: ${({ theme }) => theme.color.GRAY500};
    }

    & > p {
      ${({ theme }) => theme.typography.UI12R};
      color: ${({ theme }) => theme.color.GRAY300};
      margin-bottom: 6px;
    }

    a:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    a {
      width: fit-content;
      margin-bottom: 6px;
      font-weight: ${({ theme }) => theme.fontWeight.SEMI_BOLD};

      &:nth-child(n + 2) {
        font-weight: ${({ theme }) => theme.fontWeight.REGULAR};
      }
    }
  }

  flex-direction: column;
`;

const Copyright = styled.div`
  ${({ theme }) => theme.typography.UI12R};
  color: ${({ theme }) => theme.color.GRAY300};
  margin-bottom: 32px;
`;
